import React from "react";
import "whatwg-fetch";
import Cookies from "js-cookie";
import * as Sentry from "@sentry/react";

import { ReversePairing } from './ScrtWin/ReversePairing';
import PairingScreen from './components/PairingScreen';
import { defaultAnimationDuration, isFactors, logError, logInfo, parseQueryParams, setAnimationDuration } from "./helpers";
import Survey from "./Survey";
import AnswerSubmitter from "./AnswerSubmitter";
import LoadingScreen from "./LoadingScreen";
import { NewScrtWin, ScrtWin as ClassicScrtWin, WithScrtWin, BurgerIcon } from './ScrtWin/index';
import { checkUpdate, delay, getStaticImgUrl, groupBy, isFactor, transformServerSideAnswer } from './utils';
import { apiRequest } from './requestUtils';

const isApk = process.env.REACT_APP_TYPE === 'apk';
const ScrtWin = isApk ? NewScrtWin : ClassicScrtWin;

const queryParams = parseQueryParams();
const ALL_AROUND_OPTIONAL = queryParams.opt === 'y';

export class SurveyManager extends WithScrtWin {
    constructor(props) {
        super(props);
        this.routeMatchParams = props.match ? props.match.params : {};
        this.deviceId = props.deviceId || this.routeMatchParams.deviceId;
        this.fixedRespondentId = queryParams.rid;
        this.respondentMetadata = queryParams.rmetadata;
        this.fixedSurveyId = parseInt(queryParams.sid, 10);
        this.answerSubmitter = new AnswerSubmitter(this.deviceId, this.fixedRespondentId, this.respondentMetadata);
        this.locationId = this.routeMatchParams.location;
        this.questionnaireId = this.routeMatchParams.questionnaireId
            && parseInt(this.routeMatchParams.questionnaireId, 10);
        this.questionId = this.routeMatchParams.questionId && parseInt(this.routeMatchParams.questionId, 10);
        this.state = {
            poorCondition: {},
            surveyData: {},
            currentQuestionnaire: 0,
            startPage: this.routeMatchParams.startPage || null,
            questionnaires: []
        };
    }

    render() {
        let scrtWin;
        if (this.state.scrtWin) {
            scrtWin = <ScrtWin deviceId={this.deviceId || 'None'} destroy={this._closeScrtWin.bind(this)}/>
        }
        let burgerIcon;
        if (this.state.showBurger && window.cordova) {
            burgerIcon = <BurgerIcon />
        }
        if (this.state.noSurveyAttached || !this.state.questionnaires || !this.state.questionnaires.length) {
            console.log(this.state);
            return (
                <div onClick={this._loadScrtWin.bind(this)}>
                    <LoadingScreen
                        isWaitingForSurveyAssignment={this.state.noSurveyAttached}
                        hidden={!!scrtWin}
                        openScrtWin={() => this.setState({scrtWin: true})}
                    />
                    {burgerIcon}
                    {scrtWin}
                </div>);
        }

        let currentQuestionnaire = this._getCurrentQuestionnaire();
        window.surveyId = currentQuestionnaire.id;
        this._getCurrentQuestionnaireImages();
        return (
            <div onClick={this._loadScrtWin.bind(this)} className={this.isWidget ? 'widget' : ''}>
                {scrtWin}
                {burgerIcon}
                <Survey
                    pages={SurveyManager._prepareQuestions(currentQuestionnaire.questions)}
                    questionnaireId={currentQuestionnaire.id}
                    languages={currentQuestionnaire.languages}
                    backgroundImageUrl={currentQuestionnaire.defaultBackgroundImageUrl}
                    theme={currentQuestionnaire.theme}
                    backgroundColor={currentQuestionnaire.defaultBackgroundColor}
                    texts={this.state.webAppTexts}
                    logo={this.state.greatRateLogo}
                    locationId={this.locationId}
                    deviceId={this.deviceId}
                    poorCondition={this.state.poorCondition}
                    startPage={this.state.startPage}
                    fixedPage={this.fixedPage}
                    notifyPageChange={this._notifyPageChange.bind(this)}
                    submitAnswerPackage={this._submitAnswerPackage.bind(this)}
                    sendUnsentAnswers={this._sendUnsentAnswers.bind(this)}
                    finishSurvey={this._finishSurvey.bind(this)}
                    ref="survey"
                    hidden={isApk && this.state.scrtWin}
                />
            </div>
        );
    }

    componentDidMount() {
        this._fetchApiData()
    }

    static _extendTheme(theme) {
        return { basedOn: theme.name.toLowerCase().indexOf("light") >= 0 ? 'Light' : 'Dark', ...theme}
    }
    _fetchApiData() {
        let path;
        console.log('this.locationId', this.locationId);
        console.log('this.deviceId', this.deviceId);
        if (this.locationId) {
            path = `package/location/${this.locationId}`;
        } else if (this.deviceId) {
            path = `package/device/${this.deviceId}`;
        } else if (this.questionnaireId) {
            path =  `package/questionnaire/${this.questionnaireId}`;
        } else {
            return;
        }
        console.log('navigator.getBattery', navigator.getBattery);
        if (navigator.getBattery) {
            let batteryPromise = navigator.getBattery();
            console.log('batteryPromise', batteryPromise);
            console.log('batteryPromise.then', batteryPromise.then);
            if (batteryPromise && batteryPromise.then) {
                Promise.race([
                    batteryPromise,
                    delay(3000, 'TIMED_OUT'),
                ]).then((batt) => {
                    console.log('batt', batt);
                    this._fetchApiDataFromUrl(batt === 'TIMED_OUT' ? path : path + '?batteryLevel=' + batt.level)
                }).catch((error) => console.error('Issue checking battery level', error));
                return;
            }
        }

        this._fetchApiDataFromUrl(path)
    }

    _fetchApiDataFromUrl(path) {
        console.log('_fetchApiDataFromUrl - path', path);
        console.log('_fetchApiDataFromUrl - this.fixedRespondentId', this.fixedRespondentId);
        if (this.fixedRespondentId) {
            apiRequest(`answers/${this.fixedRespondentId}`)
                .then((data) => {
                    window.userData = {
                        ...data,
                        divisionIds: data.respondentDivisions ? data.respondentDivisions.map((d) => d.divisionId) : [],
                        answersRawMap: groupBy(data.answers, (a) => a.questionId),
                    };
                    this._fetchApiDataFromUrlAfterFetchRespondend(path)
                });
        } else {
            this._fetchApiDataFromUrlAfterFetchRespondend(path)
        }
    }
    _fetchApiDataFromUrlAfterFetchRespondend(path) {
        apiRequest(path)
            .then((data) => {
                console.log('data', data);
                if (data.questionnaires && !data.questionnaires.length) {
                    this.setState({noSurveyAttached: true});
                    setTimeout(this._fetchApiData.bind(this), 30000);
                    return;
                }
                // Check again in 5 minutes
                setTimeout(this._fetchApiData.bind(this), 300000);

                if (this.dataHash && data.hash === this.dataHash) {
                    logInfo('Same Hash');
                    return;
                }
                localStorage.setItem('lastData', JSON.stringify(data));
                this._setFetchedData(data);
            })
            .catch((error) => {
                console.log('error in _fetchApiDataFromUrlAfterFetchRespondend', error);
                console.error(error);
                const latestFetchedData = JSON.parse(localStorage.getItem('lastData'));

                if (error.name === 'ServerError') logInfo('REQUEST FAILED');
                if (latestFetchedData) {
                    this._setFetchedData(latestFetchedData, false, true);
                }
                if (!navigator.onLine) {
                    const onOnline = () => {
                        this._fetchApiData();
                        window.removeEventListener('online', onOnline);
                    }
                    window.addEventListener('online', onOnline);
                } else {
                    setTimeout(this._fetchApiData.bind(this), 30000);
                }
            });
    }

    _setFetchedData(data, loadingNextData, stopReload) {
        if (!loadingNextData) {
            if (this.refs.survey && this.refs.survey.hasStarted) {
                // Save the data
                this.nextData = data;
                return;
            }
            if (this.dataHash && this.state.questionnaires.length && !stopReload) { // if (this.dataHash && data.hash === this.dataHash) { this doesn't get triggered
                window.location.reload(true)
            }
        }
        this.dataHash = data.hash;

        this.setState({noSurveyAttached: false});
        const questionnaires = this.fixedSurveyId && data.questionnaires
            ? data.questionnaires.filter((survey) => survey.id === this.fixedSurveyId)
            : data.questionnaires;
        this._checkResumeSurveyData(questionnaires);

        let newState = this._prepareSurveyState({
            questionnaires,
            poorCondition: { disableAnimations: data.disableAnimations, disableBackground: data.disableBackground,  },
            webAppTexts: SurveyManager._fixWebAppTexts(data.webAppTexts),
            greatRateLogo: data.greatRateLogo,
        });
        setAnimationDuration(data.disableAnimations ? 0 : defaultAnimationDuration);

        if (data.questionnaires && data.questionnaires[0] && data.questionnaires[0].languages) {
            window.defaultLanguage = data.questionnaires[0].languages[0].code;
        }

        this.setState(newState);
    }
    static _fixWebAppTexts(webAppTextsArray) {
        if (!Array.isArray(webAppTextsArray)) {
            return webAppTextsArray;
        }
        let webAppTexts = {};
        webAppTextsArray.map((textObj) => webAppTexts = {...webAppTexts, ...textObj});
        return webAppTexts;
    }

    _checkResumeSurveyData(questionnaires) {
        if (!window.userData || !window.userData.answersRawMap) {
            return;
        }
        window.userData.surveyData = {};
        questionnaires[0].questions.forEach((q) => {
            const rawAnswerData = window.userData.answersRawMap[q.id];
            if (!rawAnswerData) return;
            if (q.maxAnswers > 1 || q.maxAnswers === 0) {
                window.userData.surveyData[q.id] = { questionId: q.id, optionId: rawAnswerData.map((a) => a.questionOptionId) }
                return;
            }
            if (isFactor(q)) {
                window.userData.surveyData[q.id] = rawAnswerData.map(transformServerSideAnswer);
                return;
            }

            window.userData.surveyData[q.id] = transformServerSideAnswer(rawAnswerData[0]);
        })
    }
    _prepareSurveyState(newState) {
        return newState;
    }

    _getCurrentQuestionnaireImages() {
        let imgs = [];
        if (this.state.questionnaires) {
            this.state.questionnaires.map(
                (q, i) => setTimeout(() => imgs = imgs.concat(this._getQuestionnaireImages(q)), i * 15000)
            );
        }

        return imgs;
    }

    _getQuestionnaireImages(questionnaire) {
        let imgTypes = ['smileys', 'image', 'factors'],
            imgs = [],
            imgQuestions = questionnaire.questions.filter((q) => imgTypes.indexOf(q.type.family) > -1),
            getImageContainersFromQuestion = (q) => isFactors(q) ? q.properties : q.options,
            getImagesFromContainer = (o) => o.imgUrl && getStaticImgUrl(o.imgUrl),
            onlyUnique = (v, idx, arr) => arr && (arr.indexOf(v) === idx);

        if (questionnaire.defaultBackgroundImageUrl) {
            imgs.push(getStaticImgUrl(questionnaire.defaultBackgroundImageUrl))
        }
        if (questionnaire.questions[0] && questionnaire.questions[0].backgroundImageUrl) {
            imgs.push(getStaticImgUrl(questionnaire.questions[0].backgroundImageUrl));
        }
        imgQuestions.forEach((q) => imgs.push(...getImageContainersFromQuestion(q).map(getImagesFromContainer).filter(Boolean)));

        questionnaire.questions.map((q) => q.backgroundImageUrl).filter(Boolean).forEach((q) => imgs.push(getStaticImgUrl(q.backgroundImageUrl)));

        imgs = imgs ? imgs.filter(onlyUnique) : []; // Uniqueness
        imgs.filter(Boolean).map((imgSrc) => {
            const tmpImg = new Image();
            tmpImg.src = imgSrc;
            return tmpImg;
        })
        return imgs;
    }

    _getCurrentQuestionnaire() {
        if (this.state.questionnaires && this.state.currentQuestionnaire !== false) {
            const currentQuestionnaire = this.state.questionnaires[this.state.currentQuestionnaire];
            return {...currentQuestionnaire, theme: SurveyManager._extendTheme(currentQuestionnaire.theme)};
        }

        return {}
    }

    _finishSurvey() {
        window.defaultLanguage = this.state.questionnaires[this._getNextQuestionnaire()].languages[0].code || 'sv';
        this.refs.survey && this.refs.survey._resetSurvey();
        if (this.answerSubmitter) {
            this.answerSubmitter._increaseInternalRespondentId();
        }

        if (this.nextData) {
            setTimeout(() => window.navigator.onLine && window.location.reload(true), 500);
            this._setFetchedData(this.nextData, true);
            this.nextData = null;
            return;
        }
        if (window.cordova) {
            checkUpdate()
                .then((code) => {
                    if (code === 202) this._setNextQuestionnaireInState();
                })
                .catch(() => this._setNextQuestionnaireInState());
        } else {
            this._setNextQuestionnaireInState();
        }
    }

    _getNextQuestionnaire() {
        return (this.state.currentQuestionnaire + 1) < this.state.questionnaires.length
            ? this.state.currentQuestionnaire + 1
            : 0
    }
    _setNextQuestionnaireInState() {
        this.setState({currentQuestionnaire: this._getNextQuestionnaire()});
    }

    _submitAnswerPackage(answerPackage) {
        if (this.answerSubmitter) {
            this.answerSubmitter._submitAnswerPackage(answerPackage, this._getCurrentQuestionnaire().id);
        }
    }

    _sendUnsentAnswers() {
        if (this.answerSubmitter) {
            this.answerSubmitter._cleanAnswerQueue();
            this.answerSubmitter._dequeAnswer(0);
        }
    }

    _notifyPageChange() {}

    static _prepareQuestions(questions) {
        return questions.map((q) =>
            ALL_AROUND_OPTIONAL || ['number', 'textline', 'comments'].indexOf(q.type.tag) > -1
                ? {...q, notRequired: true}
                : q
        );
    }
}
export class WebApp extends SurveyManager {
    isApp = true;
}
export class Previewer extends SurveyManager {
    constructor(props) {
        super(props);
        this.fixedPage = !!this.questionId;
    }

    _prepareSurveyState(newState) {
        if (this.questionnaireId) {
            let selectedQuestionnaire = newState.questionnaires[0];
            if (this.questionId) {
                let i = selectedQuestionnaire.questions.findIndex((q) => q.id === this.questionId);

                if (i > -1) {
                    newState['startPage'] = i;
                    newState['fixedPage'] = true;
                }
            }
            let surveyDataJsonString = localStorage.getItem('surveyData');
            if (surveyDataJsonString) {
                newState['initialSurveyData'] = JSON.parse(surveyDataJsonString);
            }
        }
        return newState;
    }
}

export class Home extends WithScrtWin {
    constructor(props) {
        super(props);
        if (navigator.userAgent.match(/Android.+AppleWebKit\/5/)) {
            window.webAppDevType = 'android';
        } else if (navigator.userAgent.match(/iPad.+AppleWebKit\/6/)) {
            window.webAppDevType = 'ipad';
        } else if (navigator.userAgent.match(/iPhone.+AppleWebKit\/6/)) {
            window.webAppDevType = 'iphone';
        }

        if (document.body.className.indexOf(window.webAppDevType) === -1) {
            document.body.className += ' ' + window.webAppDevType;
        }
        const deviceIdFromLocalStorage = localStorage.getItem('deviceId');
        const deviceIdFromCookies = Cookies.get('deviceId');

        if (!deviceIdFromCookies && deviceIdFromLocalStorage) {
            Cookies.set('deviceId', deviceIdFromLocalStorage);
        }

        this.state = {deviceId: deviceIdFromCookies || deviceIdFromLocalStorage, showReverse: !!window.cordova };
        this.setDevice = this.setDevice.bind(this);
        this.togglePairingMode = this.togglePairingMode.bind(this);
    }

    componentDidMount() {
        if (!this.state.deviceId) {
            this._fetchDeviceCode()
        }
    }
    togglePairingMode() {
        this.setState({ showReverse: !this.state.showReverse });
    }
    setDevice({ deviceId, deviceName }) {
        this.setState({ deviceId, deviceName });
        Sentry.setContext("device", { deviceId, deviceName });
        Cookies.set('deviceId', deviceId);
        localStorage.setItem('deviceId', deviceId);
    }

    render() {
        if (this.state.deviceId)
            return <WebApp deviceId={this.state.deviceId}/>;

        if (this.state.scrtWin) {
            return <ScrtWin deviceId='None' destroy={() => this.setState({scrtWin: false})}/>;
        }

        return (
            <div className={!this.state.code ? 'container' : ''} onClick={this._loadScrtWin.bind(this)}>
                {this.state.showBurger && window.cordova && <BurgerIcon />}
                {this.state.code
                    ? this.state.showReverse
                        ? <ReversePairing setDevice={this.setDevice} switchBack={this.togglePairingMode} />
                        : (
                            <PairingScreen
                                setDevice={this.setDevice}
                                switchBack={(!!window.cordova || window.location.hash.includes('test')) && this.togglePairingMode}
                                pairingCode={this.state.code}
                            />)
                    : <LoadingScreen openScrtWin={() => this.setState({scrtWin: true})} />}
            </div>);
    }

    _fetchDeviceCode() {
        apiRequest('package/device')
            .then((data) => this.setState({ code: data.code }))
            .catch((error) => {
                if (!navigator.onLine) {
                    const onOnline = () => {
                        this._fetchDeviceCode();
                        window.removeEventListener('online', onOnline);
                    }
                    window.addEventListener('online', onOnline);
                }
                logError('Request failed', error)
            });
    }
}
